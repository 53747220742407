<template>
  <div class="headticket">
    <v-container class="viewticket">
          <div class="card-top-frame">
      <p class="farename">{{ farename }}</p>
      <p class="farerule">{{ farerule }}</p>
      <p class="fareexp">{{ fareexp }}</p>
    </div>
    <div class="flex-wrapper-one">
      <p class="passenger">{{ passenger }}</p>
      <div class="frame-11">
        <p class="quan-twan">{{ passengername }}</p>
        <p class="vare-value">{{ farevalue }}</p>
      </div>
      <img
        alt=""
        class="vector"
        :src="require('@/assets/myticket/mobileqr-img.svg')"
      />
      <v-btn small rounded class="button primary" v-on:click="scanQR()" >{{$t('ScanQR') }}
        </v-btn>       
         
    </div>
      <!-- <p>{{this.item}}</p> -->
    </v-container>
  </div>
 
</template>


<script>
export default {
  name: "ViewTicket",
  data() {
    return {
      item:this.$route.params.data,
      ticktoken:this.$route.params.data.ticket_token ,
      farename:this.$route.params.data.fare_name ,
      farerule:this.$route.params.data.max_trip ,
      fareexp:this.$route.params.data.ticket_expired_date ,
      passengername:this.$route.params.data.psg_name ,
      farevalue:this.$route.params.data.fare_value,
    }
  },
  props: {
    // farename: {
    //   type: String,
    //   default: "All Route - 3 Days Ticket"
    // },
    // farerule: {
    //   type: String,
    //   default: "Total 6 trip for 3 days - 2 trip/day"
    // },
    // fareexp: {
    //   type: String,
    //   default:
    //     "Multi-days ticket is valid until 22 January 2021"
    // },
    passenger: { type: String, default: "Passenger" },
    // passengername: { type: String, default: "Quan Twan" },
    // farevalue: { type: String, default: "600,000 VNĐ" }
  },
  methods:{
    scanQR() {
      // this.$emit("onClickView", this.params.value)
      this.$router.push({
        name: 'ScanQR',
        params: {
          data:this.ticktoken
        }
      })
    },

    // clickView() {
      
    //   this.onClickView
    //   console.log("okok")
    // },
    // clickNo(){
    //   this.dialog = false
    // },
  },
};
</script>

<style lang="scss" scoped>
.headticket{
  background-color: rgba(245, 255, 247, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.flex-wrapper {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 20px ; 
}
.back-img {
  margin-right: 12px;
}
.head-ticket {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: black;
  margin: 0;
}
.viewticket {
  background-color: rgba(245, 255, 247, 1);
  padding: 30px 15px 219px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-top-frame {
  background-color:  white;
  margin-bottom: 17px;
  border-radius: 12px;
  padding: 9px 51px 18px 50px;
  box-shadow: 0 4px 8px 0 transparent-black,
    0 1px 2px 0 transparent-black-2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.farename {
  font-family: "Roboto";
  color: rgba(72, 158, 75, 1);
  text-align: center;
}
.farerule {
  font-family: "Roboto";
  color: rgba(134, 134, 134, 1);
  text-align: center;
  margin-bottom: 26px;
}
.fareexp {
  // @include roboto-10-bold;
  color: rgba(75, 177, 78, 1);
  font-style: italic;
  text-align: center;
}
.flex-wrapper-one {
  padding: 22px 20px 27px 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image:url('~@/assets/myticket/card-bg.svg');
}
.passenger {
  width: 345px;
  font-family: "Roboto";
  color: rgba(173, 179, 188, 1);
}
.frame-11 {
  margin-bottom: 41px;
  display: flex;
  align-items: flex-start;
}
.quan-twan {
  width: 217px;
  font-family: "Roboto";
  color: rgba(60, 60, 60, 1);
}
.vare-value {
  width: 128px;
  font-family: "Roboto";
  color: rgba(242, 153, 0, 1);
  text-align: right;
  margin-top: 4px;
}
.vector {
  margin-bottom: 30px;
  margin-left: 113px;
}
.button {
  margin: auto;
}
</style>